import * as React from 'react'
import EventDetails from 'components/EventDetails/EventDetails'
import CanadianQuantumCupContent from '../../../content/events/canadian-quantum-cup'
import Seo from '../../components/Seo'

const CanadianQuantumCup = () => {
  return <EventDetails content={CanadianQuantumCupContent} />
}

export default CanadianQuantumCup

export const Head = () => {
  const { seo } = CanadianQuantumCupContent
  return (
    <Seo
      title={seo.title}
      description={seo.description}
      image={seo.image}
      imageBasePath="/events/"
    />
  )
}
