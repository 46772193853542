export default {
  seo: {
    title: `Canadian Quantum Cup`,
    description: `The ultimate quantum programming competition in Canada.`,
    image: 'canadian-quantum-cup/canadian-quantum-cup.png',
  },
  hero: {
    title: `Events`,
    description: `The ultimate quantum programming competition in Canada.`,
    image: `canadian-quantum-cup.png`,
    imageAlt: `Canadian Quantum Cup`,
  },
  overview: {
    sectionTitle: `Overview`,
    description: `
It’s official. The Canadian Quantum Cup is coming this fall. Every Canadian university will get the chance to register a team and compete amongst the best of the best. Will you be able to beat the competition, collect a prize, and bring the cup home to your university? The winning team will be invited on a trip to Toronto (with travel expenses covered) to spend a day at Xanadu and earn a winner’s trophy. The top 3 teams will win exclusive PennyLane and Xanadu-themed swag.

The Canadian Quantum Cup is a student competition from November 7-10 2023. Each university can register one team of up to 4 team members, who will compete with other Canadian universities to be the fastest to solve a series of quantum coding challenges. The competition includes 3 rounds: all-in, semifinals and finals. For each round, eligible teams have 24h to complete the coding challenges at hand. Only the top half of the teams will advance to the semifinals, and only the top 3 teams from the semifinals will advance to the finals.

If you’re a student at a Canadian university please ask one of your professors to nominate you using [this form](https://forms.gle/Mjw1Vh9ZnuRw7pUj9), or contact us at **quantumcup@xanadu.ai**. You can also reach out to us on the [Xanadu Slack](http://bit.ly/Xanadu-Slack) in the [#canadian-quantum-cup channel](https://xanadu-quantum.slack.com/archives/C060PU6D1SS). All students, from undergraduate to graduate students, and from beginners to seasoned quantum programmers can participate! 
Here’s what you need to do:

**1.** Get a professor at your university to [nominate you](https://forms.gle/Mjw1Vh9ZnuRw7pUj9) by October 23rd 2023.

**2.** Prepare by solving the challenges at [pennylane.ai](http://pennylane.ai/) and [https://pennylane.ai/codebook/](https://pennylane.ai/codebook/).

**3.** Spread the word by sharing our social media posts with the hashtag **#CanadianQuantumCup** and mentioning that your university will participate!

By participating in this event you agree to the Canadian Quantum Cup [Terms and Conditions](https://bit.ly/CQC-TermsAndConditions).
  `,
  },
  participantsSection: {
    sectionTitle: `Program Committee`,
    showImages: true,
    participants: [
      // `image`, `linkedInUrl`, `twitterUrl`, `githubUrl`, and `googleScholarUrl` are optional fields.
      // The order that participants are listed here is the order they will appear on the page.
      // {
      //   name: `Maria Schuld`,
      //   affiliation: `Research`,
      //   image: `maria-schuld.jpeg`,
      //   twitterUrl: `https://twitter.com/MariaSchuld`,
      //   githubUrl: `https://github.com/mariaschuld`,
      //   googleScholarUrl: `https://scholar.google.com/citations?user=_ih_hwUAAAAJ`,
      //   linkedInUrl: `https://za.linkedin.com/in/maria-schuld-7857b070`,
      // },
    ],
  },
  sponsors: {
    title: `Sponsors`,
    description: `Description text for the sponsors section goes here.`,
    logos: [
      // {
      //   url: `https://www.xanadu.ai/`,
      //   image: `xanadu-bw.png`,
      //   imageAlt: `Xanadu`,
      // },
    ],
  },
  eventSchedule: {
    sectionTitle: `Schedule`,
    timezoneTooltip: `Eastern Standard Time`,
    // The column headers in this list appear after the Time column.
    columnHeaders: ['Event', 'Participants'],
    // When updating the category of a row, double check
    // that the category name matches a category here.
    // Otherwise, the correct colour will not appear on the row
    categories: [`Opening & Closing Ceremonies`, `Competition Rounds`],
    schedule: [
      {
        day: `November 7, 2023`,
        scheduleRows: [
          {
            category: `Opening & Closing Ceremonies`,
            time: `11:30am - 12:00pm`,
            columnsAfterTime: [
              { text: `Opening Ceremony`, description: `` },
              {
                text: `All Teams`,
                description: `All teams should join this opening session on Google Meet (details to be provided via email) to learn about the event before diving into the competition.`,
              },
            ],
          },
          {
            category: `Competition Rounds`,
            time: `12:00pm`,
            columnsAfterTime: [
              {
                text: `All-in Competition (Round 1) begins`,
                description: ``,
              },
              {
                text: `All Teams`,
                description: `All teams start the first round of the coding competition.`,
              },
            ],
          },
        ],
      },
      {
        day: `November 8, 2023`,
        scheduleRows: [
          {
            category: `Competition Rounds`,
            time: `12:00pm`,
            columnsAfterTime: [
              {
                text: `All-in Competition (Round 1) ends`,
                description: ``,
              },
              {
                text: `All Teams`,
                description: `The first round of the competition ends.`,
              },
            ],
          },
          {
            category: `Competition Rounds`,
            time: `1:00pm`,
            columnsAfterTime: [
              {
                text: `Semifinals Competition (Round 2) begins`,
                description: ``,
              },
              {
                text: `Top 50% of Teams`,
                description: `The top 50% of teams start the second round of the coding competition.`,
              },
            ],
          },
        ],
      },
      {
        day: `November 9, 2023`,
        scheduleRows: [
          {
            category: `Competition Rounds`,
            time: `1:00pm`,
            columnsAfterTime: [
              {
                text: `Semifinals Competition (Round 2) ends`,
                description: ``,
              },
              {
                text: `Top 50% of Teams`,
                description: `The second round of the competition ends.`,
              },
            ],
          },
          {
            category: `Competition Rounds`,
            time: `2:00pm`,
            columnsAfterTime: [
              {
                text: `Finals Competition (Round 3) begins`,
                description: ``,
              },
              {
                text: `Top 3 Teams`,
                description: `The top 3 teams advance to the final round of the coding competition.`,
              },
            ],
          },
        ],
      },
      {
        day: `November 10, 2023`,
        scheduleRows: [
          {
            category: `Competition Rounds`,
            time: `2:00pm`,
            columnsAfterTime: [
              {
                text: `Finals Competition (Round 3) ends`,
                description: ``,
              },
              {
                text: `Top 3 Teams`,
                description: `The coding competition is over!`,
              },
            ],
          },
          {
            category: `Opening & Closing Ceremonies`,
            time: `3:00pm - 3:30pm`,
            columnsAfterTime: [
              {
                text: `Closing Ceremony`,
                description: ``,
              },
              {
                text: `All Teams`,
                description: `All teams can join this closing session on Google Meet (details to be provided via email) to celebrate a great event and congratulate the winning teams.`,
              },
            ],
          },
        ],
      },
    ],
  },
  location: {
    sectionTitle: `Additional Information`,
    description: ``,
  },
}
